import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import fetchUrl from "../../api/index";
import { DASHBOARD_API } from "../../utils/constant";
import { hideLoader, showLoader } from "../loader/reducer";

let configObj = {
  baseUrl: process.env.REACT_APP_URL,
  contentType: "application/json",
};
export const fetchDashboard = createAsyncThunk(
  "dashboard/fetch",
  async (_, { dispatch }) => {
    const type = "get";
    const url = DASHBOARD_API.get;
    try {
      dispatch(showLoader());
      const response = await fetchUrl({ type, url, config: configObj });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);
export const addDashboard = createAsyncThunk(
  "dashboard/add",
  async (data, { dispatch }) => {
    const type = "post";
    const url = DASHBOARD_API.add;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response?.status === 201) {
        dispatch(fetchDashboard());
        toast.success("Dashboard created successfully.");
        return response.data;
      } else {
        toast.error("Please check your credentials and try again.");
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateDashboard = createAsyncThunk(
  "dashboard/update",
  async (data, { dispatch }) => {
    dispatch(showLoader());
    const type = "patch";
    const url = `/analytics-dashboard/${data.dashboard_id}/`;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      console.log({ response });
      if (response.message) {
        toast.success(response.message);
        dispatch(fetchDashboard());
        return response;
      } else {
        toast.error(response.error);
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);
export const getDashboardDetails = createAsyncThunk(
  "dashboard/getdetails",
  async (data, { dispatch }) => {
    dispatch(showLoader());
    const type = "get";
    const url = `/analytics-dashboard/${data.dashboard_id}/?version=${data.version}`;
    try {
      const response = await fetchUrl({
        type,
        url,
        data: null,
        config: configObj,
      });
      if (response) {
        return response;
      } else {
        toast.error(response.error);
        throw new Error(response.error || "Failed to fetch dashboard details.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching dashboard details.");
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const updateWidgetsDashboard = createAsyncThunk(
  "dashboard/updatewidgets",
  async (data, { dispatch }) => {
    dispatch(showLoader());
    const type = "put";
    const url = `/analytics-dashboard/${data.dashboard_id}/`;
    try {
      const response = await fetchUrl({
        type,
        url,
        data: data.body,
        config: configObj,
      });
      if (response.status === 200) {
        dispatch(fetchDashboard());
        dispatch(
          getDashboardDetails({
            dashboard_id: data.dashboard_id,
            version: data.version,
          })
        );

        toast.success(response.data);
        return response;
      } else {
        toast.error(response.error);
        throw new Error(
          response.error || "Failed to update dashboard widgets."
        );
      }
    } catch (error) {
      toast.error("An error occurred while updating dashboard widgets.");
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);

// ------------ version ----------
export const getDashboardVersion = createAsyncThunk(
  "dashboard/version",
  async (data) => {
    const type = "get";
    const url = `/analytics-dashboard/${data.dash_id}/version/`;
    try {
      const response = await fetchUrl({ type, url, config: configObj });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const addDashboardVersion = createAsyncThunk(
  "dashboard/addversion",
  async (data, { dispatch }) => {
    const type = "post";
    const url = `/analytics-dashboard/version/add/`;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response.status === 201) {
        toast.success(response.data);
        dispatch(getDashboardVersion({ dash_id: data.entity_id }));
        dispatch(
          getDashboardDetails({
            dashboard_id: data.entity_id,
            version: data.version_number,
          })
        );
        return response.data;
      } else {
        toast.error(
          response.error
            ? JSON.stringify(response.error)
            : "Please enter valid number."
        );
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const publishDashboardVersion = createAsyncThunk(
  "dashboard/publish",
  async (data, { dispatch }) => {
    dispatch(showLoader());
    const type = "put";
    const url = `/analytics-dashboard/${data.dashboard_id}/version/${data.version_id}`;
    try {
      const response = await fetchUrl({ type, url, config: configObj });
      if (response.message) {
        dispatch(fetchDashboard());
        dispatch(getDashboardVersion({ dash_id: data.dashboard_id }));
        dispatch(
          getDashboardDetails({
            dashboard_id: data.dashboard_id,
            version: data.version_id,
          })
        );
        console.log(response);

        return response;
      } else {
        toast.error(response.error);
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);

// ------------ Widget -----------
export const fetcAllhDashboardWidget = createAsyncThunk(
  "dashboardWidget/fetchall",
  async () => {
    const type = "get";
    const url = "/api/widgets/list";
    try {
      const response = await fetchUrl({ type, url, config: configObj });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const addDashboardWidget = createAsyncThunk(
  "dashboardWidget/add",
  async (data) => {
    const type = "post";
    const url = "/api/widgets/add";
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response?.status === 201) {
        toast.success("Widget created successfully.");
        return response.data;
      } else {
        toast.error("Please check your input and try again.");
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const getDashboardWidget = createAsyncThunk(
  "dashboardWidget/get",
  async (data) => {
    const type = "get";
    const url = `/api/widgets/${data.widget_id}`;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response?.status === 201) {
        toast.success("Widget created successfully.");
        return response.data;
      } else {
        toast.error("Please check your settings and try again.");
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const updateDashboardWidget = createAsyncThunk(
  "dashboardWidget/update",
  async (data, { dispatch }) => {
    const type = "put";
    const url = `/api/widgets/${data.widget_id}`;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response.message) {
        toast.success(response.message);
        dispatch(fetcAllhDashboardWidget());
        return response;
      } else {
        toast.error(response.error);
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  "dashboardWidget/delete",
  async (data) => {
    const type = "put";
    const url = `/api/widgets/${data.widget_id}`;
    try {
      const response = await fetchUrl({
        type,
        url,
        data: data.body,
        config: configObj,
      });

      if (response.status == 204) {
        toast.success("Widget Delete successfully!");
        return data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);

// Utility functions for setting state
const setLoading = (state) => {
  state.status = "loading";
  state.statuscode = 2;
  state.aiquerystr = [];
};
const setSucceeded = (state) => {
  state.status = "succeeded";
  state.statuscode = 1;
};
const setFailed = (state, action) => {
  state.status = "failed";
  state.statuscode = 0;
  state.error = action.error.message;
};

// Define the initial state
const initialState = {
  data: [],
  status: "idle",
  dashboardstatus: "idle",
  statuscode: 0,
  aiquerystr: "",
  aiissuesstr: "",
  querysettitle: "",
  versionlist: [],
  widgetlist: [],
  dashboardDetails: {},
  widgetInfo: null,
  error: null,
};

const querysetsSlice = createSlice({
  name: "querysets",
  initialState,
  reducers: {
    resetPartialState: (state, action) => {
      // Loop over the keys in the action payload and reset them
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Dashboard
      .addCase(fetchDashboard.pending, setLoading)
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.data = action.payload;
        setSucceeded(state);
      })
      .addCase(fetchDashboard.rejected, setLoading)

      // Add Dashboard
      .addCase(addDashboard.pending, setLoading)
      .addCase(addDashboard.fulfilled, (state, action) => {
        state.data.results.push({ ...action.payload });
        setSucceeded(state);
      })
      .addCase(addDashboard.rejected, setFailed)

      // udpate Dashboard
      .addCase(updateDashboard.pending, setLoading)
      .addCase(updateDashboard.fulfilled, (state, action) => {
        state.data.results.push({ ...action.payload });
        setSucceeded(state);
      })
      .addCase(updateDashboard.rejected, setFailed)
      // det details Dashboard
      .addCase(getDashboardDetails.pending, setLoading)
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.dashboardDetails = [];
        state.dashboardDetails = action.payload;
        setSucceeded(state);
      })
      .addCase(getDashboardDetails.rejected, setFailed)
      // update widgets Dashboard
      .addCase(updateWidgetsDashboard.pending, setLoading)
      .addCase(updateWidgetsDashboard.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(updateWidgetsDashboard.rejected, setFailed)
      // ---------------------------------- version ------------------------
      // Fetch Dashboard version
      .addCase(getDashboardVersion.pending, setLoading)
      .addCase(getDashboardVersion.fulfilled, (state, action) => {
        setSucceeded(state);
        state.versionlist = action.payload;
      })
      .addCase(getDashboardVersion.rejected, setFailed)

      // Add Version Dashboard
      .addCase(addDashboardVersion.pending, setLoading)
      .addCase(addDashboardVersion.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(addDashboardVersion.rejected, setFailed)

      // Publish version Dashboard
      .addCase(publishDashboardVersion.pending, setLoading)
      .addCase(publishDashboardVersion.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(publishDashboardVersion.rejected, setFailed)
      // ---------------------------------- Widget ------------------------
      // Add Widget
      .addCase(addDashboardWidget.pending, setLoading)
      .addCase(addDashboardWidget.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(addDashboardWidget.rejected, setFailed)

      // Fetch All Widget
      .addCase(fetcAllhDashboardWidget.pending, setLoading)
      .addCase(fetcAllhDashboardWidget.fulfilled, (state, action) => {
        setSucceeded(state);
        state.widgetlist = action.payload;
      })
      .addCase(fetcAllhDashboardWidget.rejected, setLoading)
      // get info Widget
      .addCase(getDashboardWidget.pending, setLoading)
      .addCase(getDashboardWidget.fulfilled, (state, action) => {
        setSucceeded(state);
        state.widgetInfo = action.payload;
      })
      .addCase(getDashboardWidget.rejected, setLoading)

      // update info Widget
      .addCase(updateDashboardWidget.pending, setLoading)
      .addCase(updateDashboardWidget.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(updateDashboardWidget.rejected, setLoading)
      // delete info Widget
      .addCase(deleteDashboardWidget.pending, setLoading)
      .addCase(deleteDashboardWidget.fulfilled, (state, action) => {
        setSucceeded(state);
      })
      .addCase(deleteDashboardWidget.rejected, setLoading);
  },
});

export const { resetPartialState } = querysetsSlice.actions;

export default querysetsSlice.reducer;
