import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/index';
import { AuthProvider } from "./utils/AuthContext";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading persisted state...</div>} persistor={persistor}>
      <AuthProvider>
        <Router>
          <Suspense fallback={<div>Loading application...</div>}>
            <App />
          </Suspense>
        </Router>
      </AuthProvider>
    </PersistGate>
  </Provider>
);
