import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false
};

export const Loader = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showLoader: (state) => {
            state.show = true;
        },
        hideLoader: (state) => {
            state.show = false;
        },
    },
});

export const {
    showLoader,
    hideLoader
} = Loader.actions;

export default Loader.reducer;
