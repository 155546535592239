import React, { Suspense, useEffect, useState } from "react";
import "../../styles/connection.css";
import AISubscriptionHeader from "../CommonComponents/AISubscriptionHeader";
import { Outlet } from "react-router-dom";
import BreadcrumbComponent from "../CommonComponents/BreadcrumbComponent";
import MasterCssSidebar from "../CommonComponents/MasterCssSidebar";
import BannerImageWithBottomButton from "../CommonComponents/BannerImageWithBottomButton";
import illustration from "../../../../assets/images/mastercss/WelcomeImg.png";
import { MASTER_CSS } from "../../../../utils/constant";
import fetchUrl from "../../../../api";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Offcanvas,
  Row,
  Table,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Header from "../../../../components/common/Header";

const routes = [{ path: "/", breadcrumb: "Home" }];
const MasterCss = () => {
  const containerStyle = {
    backgroundImage: `url(${illustration})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const [userCss, setUserCss] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cssFile, setCssFile] = useState(null);

  useEffect(() => {
    getUserCss();
  }, []);

  const getUserCss = async () => {
    const url = `${MASTER_CSS.GET_CSS}`;
    const type = "GET";
    const response = await fetchUrl({ type, url });
    setUserCss(response.data);
  };

  const deleteUserCssConfirmation = async () => {
    Swal.fire({
      title: "Are you sure you want to delete the Master CSS?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserCss();
      }
    });
  };

  const deleteUserCss = async () => {
    const url = `${MASTER_CSS.DELETE_CSS}`;
    const type = "DELETE";
    const response = await fetchUrl({ type, url });
    if (response) {
      window.location.reload();
      getUserCss();
    }
  };

  const addUserCss = async () => {
    const url = `${MASTER_CSS.ADD_CSS}`;
    const type = "POST";
    const formData = new FormData();
    formData.append("file_uploaded", cssFile);

    const response = await fetchUrl({
      type,
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response) {
      window.location.reload();
      getUserCss();
      setIsModalOpen(false);
    }
  };

  const openAddUserCssModal = () => {
    setIsModalOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/css" || file.name.endsWith(".css")) {
        setCssFile(file);
      } else {
        alert("Please upload a valid CSS file");
        event.target.value = null; // Clear the file input
      }
    }
  };

  const downloadUrl = () => {
    const url = process.env.REACT_APP_MASTER_CSS_URL;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = userCss.file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const buttons = [
    {
      variant: "primary",
      icon: "ri-download-2-line",
      text: "Download Css",
      onClick: downloadUrl,
    },
    {
      variant: "primary",
      icon: "ri-upload-2-line",
      text: "Upload Css",
      onClick: openAddUserCssModal,
    },
    {
      variant: "danger",
      icon: "ri-delete-bin-6-line",
      text: "Remove Css",
      onClick: deleteUserCssConfirmation,
    },
  ];

  return (
    <>
      <div className="connection-container position-relative">
        <MasterCssSidebar />
        <div className="connection-preview-container d-flex flex-column w-100">
          <Header leftLabel={<BreadcrumbComponent routes={routes} />} />
          <div className="connection-preview-container-main w-100 d-flex flex-column align-items-center">
            {userCss.file ? (
              <>
                <Col md={12} className="pad-col">
                  <Card
                    data-aos="fade-up"
                    data-aos-delay="800"
                    className="chatbot-table-section"
                  >
                    <div className="flex-wrap d-flex justify-content-end align-items-center">
                      <div className="header-title d-flex gap-3 mr-2">
                        {buttons.map(
                          ({ variant, icon, text, onClick }, index) => (
                            <Button
                              key={index}
                              variant={variant}
                              className="d-flex align-items-center gap-2"
                              onClick={onClick || undefined} // Only add onClick if it's not null
                            >
                              <span>
                                <i className={icon}></i>
                              </span>
                              {text}
                            </Button>
                          )
                        )}
                      </div>
                    </div>
                    <Card.Body>
                      <Table responsive="md" className="chatbot-table">
                        <thead>
                          <tr>
                            <th scope="col">File name</th>
                            <th scope="col">File Path</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="pad-col">{userCss.file_name}</td>
                            <td className="pad-col">
                              <a href={userCss.file} target="_blank">
                                {userCss.file}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            ) : (
              <>
                <div className="bannerimage_container">
                  <div
                    className="bannerimage_context h-90"
                    style={containerStyle}
                  >
                    <div className="bottom_button">
                      <Button className="ms-2" onClick={downloadUrl}>
                        Download Css
                      </Button>
                      <Button className="ms-2" onClick={openAddUserCssModal}>
                        Upload Css
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={isModalOpen}
        centered={true}
        onHide={() => setIsModalOpen(false)}
      >
        <div style={{ height: "250px" }} className="d-flex flex-column">
          <div className="m-5">
            <span className="popupdashboard">Upload Master Css</span>
          </div>
          <div className="ms-5">
            <input
              type="file"
              className="p-2 popupinput"
              placeholder="Upload Master Css"
              onChange={downloadUrl}
              accept=".css"
            />
          </div>
          <div className="m-5 text-end">
            <Button onClick={() => addUserCss()}>Upload Css</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MasterCss;
