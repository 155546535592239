
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Loader = () => {
    const [show, setShow] = useState(true);
    const loader = useSelector((state) => state.loader)

    useEffect(() => {
        if (loader.show) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [loader.show]);

    return (
        <div
            hidden={!loader.show}
            className={loader.show ? "bg-overlay" : ""}
            style={{ zIndex: 1000 }}
        >
            <StyledWrapper>
                <span style={{ zIndex: 1001 }} className="loader" />
            </StyledWrapper>
        </div>
    );
};

const StyledWrapper = styled.div`
  .loader {
  width: 70px;
  height: 70px;
  border: 6px dotted #4640f7;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 5px dotted yellow;
  border-style: solid solid dotted;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
`;

export default Loader;
