const KEYS = {
  user: "USER",
  authToken: "AUTH_TOKEN",
  tempData: "TEMP_DATA",
  companyData: "COMPANY_DATA",
  deviceId: "x-device-id",
  gdprKey: "gdprAccepted",
  email: "EMAIL",
  requestId: "REQUEST_ID",
  rolePermission: "PERMISSION_ROLE",
  adminPermission: "ADMIN_PERMISSION",
  notificationCount: "NOTIFICATION_COUNT",
  domain: "X-DOMAIN",
  sub: "X-SUB",
  authType: "X-LOGIN-TYPE",
  mapsKey: "map_key",
};

const USER_APIS = {
  register: "user/register/",
  login: "user/login/",
  logout: "user/logout/",
  profile: "user/profile/",
  userGenerate: "user/generate",
  passwordChange: "user/auth/password/change/",
  passwordReset: "user/password/reset/",
  passwordResetConfirm: "user/password/reset/confirm/",
  profileUpdate: "user/update-user/",
  deleteUser: "user/<tenant_id>/<user_id>/delete-user/",
  deleteInvitation: "user/<invitation_id>/delete-invitation/",
  resendInvitation: "user/<invitation_id>/resend-invitation/",
  invitationAcceptLogin: "user/<invitation_id>/invitation-accept-login/",
  sendInvitation: "user/<tenant_id>/invite-user/",
  activeAccount: "user/active-account/",
  resendAtcivateAccount: "user/resend-active-account/",
  getUserProfile: "user/external-profile",
  deleteUserProfile: "user/delete-user-profile",
  saveUserProfile: "user/save-generate",
  saveAddon: "user/save-addon",
  USER_SETTINGS: "user/user-setting/",
  EMAIL_SETTINGS: "user/email-settings/",
  PAYMENT_SETTINGS: "user/payment-settings/",
};

const STRIPE_PAYMENT_GETWAYS = {
  paymentGateway: "payment-gateway/",
  updatePaymentGateway: "payment-gateway/get-update-stripe-user",
  paymentGatewayReAuth: "payment-gateway/re-auth",
};

const DATA_SOURCE_APIS = {
  get: "/datasource/list/",
  add: "/datasource/add/",
  delete: "/datasource",
  syncdb: "/datasource/<datasource_id>/tables/sync",
  tables: "/datasource/<datasource_id>/",
  synctables: "/datasource/<datasource_id>/columns/sync",
  gettablesdescription:
    "/datasource/<datasource_id>/table/<datasource_table_id>/columns/list/",
  updatetablesdescription:
    "/datasource/<datasource_id>/table/<datasource_table_id>/columns",
};

const AUTHENTICATION_GATEWAYS = {
  userMetaAuthFields: "user/meta-auth-fields/",
};

const MASTER_GETWAYS = {
  masterCountry: "master/countries/",
  masterStates: "/states/",
};

const SUBSCRIPTION_APIS = {
  CREATE_PAYMENT_METHOD: "subscription/payment-methods/",
  CREATE_PAYMENT_COUPON: "subscription/promocodes",
  GENERATE_SUBSCRIPTION_PLAN: "subscription/generate",
  CREATE_SUBSCRIPTION_PLAN: "subscription/plans",
  GET_SUBSCRIPTION_CUSTOMER: "subscription/",
  GET_ALL_PLANS: "subscription/plans/dropdown",
  CHANGE_SUBSCRIPTION_PLAN: "subscription/plan/update",
  DOWNLOAD_INVOICE: "subscription/invoice/download",
};

const MASTER_CSS = {
  ADD_CSS: "/file/add/",
  GET_CSS: "/file/",
  DELETE_CSS: "/file/",
};
const BOT_CONTENT_API = {
  SUMMARY_COUNTS: "ai-chatbot/chats/metric",
  PROMPT: "ai-chatbot/prompt",
  FILES_INPUT: "ai-chatbot/files",
  CHAT_INPUT: "ai-chatbot/chats",
  BOT_INFO: "ai-chatbot/bot/detail",
  SNIPPET: "ai-chatbot/snippets",
  PROMPT_DETAIL: "ai-chatbot/prompt/detail",
  SNIPPET_DETAIL: "ai-chatbot/snippets/detail",
};

const CALENDAR_API = {
  AVAILABILITY_SLOTS: "ai-calendar/availablity/slots/",
  BOOKING: "ai-calendar/booking/",
  SETTINGS: "ai-calendar/settings/",
};

const REGISTRATION_SCHEMA = ["email", "password", "confirm_password"];
const USER_SETTING_SCHEMA = ["auth0_Key"];
const USER_AUTH_CREDS_SCHEMA = [
  "domain",
  "auth0_Key",
  "client_id",
  "secret",
  "connection",
];
const PASSWORD_CHANGE_SCHEMA = [
  "current_password",
  "new_password",
  "confirm_new_password",
];

const ECOMMERCE_APIS = {
  CREATE_CATEGORY: "ecommerce/categories/",
  GET_CATEGORY: "ecommerce/get-all-categories/",

  GET_ACTIVE_CATEGORY: "ecommerce/active-categories/",
  GET_CATEGORY_DETAIL: "ecommerce/categories/<category_id>",
  UPDATE_CATEGORY_DETAIL: "ecommerce/categories/<category_id>",
  DELETE_CATEGORY: "ecommerce/categories/<category_id>",

  CREATE_PRODUCT: "ecommerce/products/",
  GET_PRODUCTS: "ecommerce/get-all-products/",
  GET_PRODUCT_DETAIL: "ecommerce/products/<product_id>",
  UPDATE_PRODUCT: "ecommerce/products/<product_id>",

  PRODUCT_IMAGE_UPLOAD: "ecommerce/images/",
  GET_ORDERS: "ecommerce/get-all-orders/",
  GET_ORDER_DETAIL: "ecommerce/orders/<order_id>",

  GET_INVENTORYS: "ecommerce/get-all-inventorys/",
  UPDATE_INVENTORYS: "ecommerce/update-inventorys/",

  BULK_IMAGE_DELETE: "ecommerce/bulk-image-delete/",
};

const STATUS_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const SUBSCRIPTION_PLAN_MODEL_TEXT = {
  "Change Plan": "Are you sure you want to change the plan?",
  "Cancel Subscriptions": "Do you want to cancel this subscription?",
  "Resume Subscription": "Do you want to reactivate this subscription?",
  "Pause Subscription": "Do you want to pause this subscription?",
};

const SUBSCRIPTION_PLAN_MODEL_VALUE = {
  "Change Plan": "Change Plan",
  "Cancel Subscriptions": "canceled",
  "Resume Subscription": "reactivate",
  "Pause Subscription": "paused",
};

const ENCRYPT_DECRYPT = {
  ENCRYPT: "/web-components/encode/key",
  DECRYPT: "/web-components/decode/key",
};

const HTTP_METHODS = {
  GET: "GET",
  DELETE: "DELETE",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
};

const ERROR_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
};

const ADMIN_CUSTOMER_API = {
  GET_ALL_CUSTOMER: "admin/customers",
  GET_CUSTOMER: "admin/customer/<customer_id>",
  UPDATE_CUSTOMER: "admin/customer/<customer_id>",
  DELETE_CUSTOMER: "admin/customer/<customer_id>",
};

const QUERY_SETS_API = {
  get: "/query-set/list/",
  add: "/query-set/add/",
};
const DASHBOARD_API = {
  get: "/analytics-dashboard/list/",
  add: "/analytics-dashboard/add/",
};

module.exports = {
  KEYS,
  USER_APIS,
  USER_AUTH_CREDS_SCHEMA,
  BOT_CONTENT_API,
  MASTER_GETWAYS,
  USER_SETTING_SCHEMA,
  REGISTRATION_SCHEMA,
  PASSWORD_CHANGE_SCHEMA,
  SUBSCRIPTION_APIS,
  STRIPE_PAYMENT_GETWAYS,
  ECOMMERCE_APIS,
  STATUS_OPTIONS,
  DATA_SOURCE_APIS,
  CALENDAR_API,
  AUTHENTICATION_GATEWAYS,
  SUBSCRIPTION_PLAN_MODEL_TEXT,
  SUBSCRIPTION_PLAN_MODEL_VALUE,
  MASTER_CSS,
  ADMIN_CUSTOMER_API,
  HTTP_METHODS,
  ERROR_CODES,
  ENCRYPT_DECRYPT,
  QUERY_SETS_API,
  DASHBOARD_API,
};
