import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist"; // imports from redux-persist
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import querySetsReducer from "./quersets/reducers";
import settingReducer from "./setting/reducers";
import subscriptionReducer from "./subscription/reducers";

import aiCalendar from "./aiCalendar/reducers";
import aiChatBot from "./aiChatbot/reducers";
import formReducer from "./authentication/reducers";
import commonSettings from "./commonSettings/reducer";
import dashboardReducer from "./Dashboard/reducers";
import dataSourceReducer from "./Datasource/reducers";
import loaderReducer from "./loader/reducer";
import profileSlice from "./profile/reducer";
import reportReducer from "./reports/reducers";
import sidebarHeaderSettingsReducer from "./sidebarHeaderSettings/reducers";

import { productReducer } from "./ecommerce/product/productSlice";

const persistConfig = {
  // configuration object for redux-persist
  key: "root",
  storage, // define which storage to use
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET_KEY_REDUX || "shopdot",
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  blacklist: ["loader", "subscription"],
};

const subscriptionPersistConfig = {
  key: "subscription",
  storage: storage,
  blacklist: ["previewState"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  // blacklist: ["previewState"],
};

const combinedReducer = combineReducers({
  setting: settingReducer,
  subscription: persistReducer(subscriptionPersistConfig, subscriptionReducer),
  datasource: dataSourceReducer,
  sidebarHeaderSettings: sidebarHeaderSettingsReducer,
  formConfig: formReducer,
  profile: persistReducer(profilePersistConfig, profileSlice),
  commonSettings: commonSettings,
  aiChatBot: aiChatBot,
  product: productReducer,
  dashboard: dashboardReducer,
  querysets: querySetsReducer,
  aiCalendar: aiCalendar,
  reports: reportReducer,
  loader: loaderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return combinedReducer(undefined, { type: undefined });
  } else {
    return combinedReducer(state, action);
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { persistor, store };
