import fetchUrl from "../api";

const { KEYS, ENCRYPT_DECRYPT } = require("./constant");
const { LocalStorage } = require("./localStorage");

const setDeviceId = () => {
  // eslint-disable-next-line no-magic-numbers
  const uuid = Math.floor(Math.random() * 1000000000000);
  const date = new Date();
  const timestamp = date.getTime();

  LocalStorage.set(KEYS.deviceId, `${uuid}-${timestamp}`);
};

const generateOrderId = (value) => {
  if (value < 10000) {
    return value.toString().padStart(4, "0");
  }
  return value.toString();
};

// Function to generate a label from a field name
const generateLabel = (fieldName) => {
  // Replace underscores with spaces and capitalize each word
  return fieldName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const logout = () => {};

function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item));
  }

  const objCopy = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      objCopy[key] = deepCopy(obj[key]);
    }
  }
  return objCopy;
}

function removeNullFields(data) {
  return data.map((item) => {
    return Object.keys(item)
      .filter((key) => item[key] !== null && item[key] !== undefined)
      .reduce((newItem, key) => {
        newItem[key] = item[key];
        return newItem;
      }, {});
  });
}

function restrictToDigits(e) {
  e.target.value = e.target.value.replace(/\D/g, "");
}

const encryptKey = async (key) => {
  let encryptedKey = "";
  try {
    const type = "post";
    const url = ENCRYPT_DECRYPT.ENCRYPT;
    const data = {
      decrypted_key: key,
    };
    const resp = await fetchUrl({ type, url, data });
    encryptedKey = resp.data;
    return encryptedKey;
  } catch (error) {
    console.log(error);
    return encryptedKey;
  }
};

function formatEmail(inputEmail) {
  try {
    const formattedEmail = inputEmail
      .toLowerCase()
      .replace(/[^a-z0-9@.]/g, "")
      .trim();

    return formattedEmail;
  } catch (error) {
    console.error("Error formatting the email:", error);
    return null;
  }
}

function getLastPathnameValue(pathObject) {
  const { pathname } = pathObject;

  const lastPath = pathname.split("/").filter(Boolean).pop();

  const formattedLastPath = lastPath
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(/[-_ ]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedLastPath;
}

function formatPathname(pathObject) {
  const { pathname } = pathObject;

  const pathSegments = pathname.split("/").filter(Boolean);

  const formattedPathSegments = pathSegments.map((segment) =>
    segment
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(/[-_ ]+/)
      .map((word) => {
        return word.toLowerCase() === "ai"
          ? "AI"
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ")
  );

  return formattedPathSegments.join(" / ");
}

export {
  setDeviceId,
  logout,
  deepCopy,
  generateLabel,
  removeNullFields,
  generateOrderId,
  encryptKey,
  restrictToDigits,
  formatEmail,
  getLastPathnameValue,
  formatPathname,
};
