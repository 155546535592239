import React, { createContext, useState, useContext, useEffect } from "react";
import fetchUrl from "../api";
import { USER_APIS } from "../utils/constant";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../store/loader/reducer";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(localStorage.getItem("AUTH_TOKEN"));
  const [loading, setLoading] = useState(true);

  const isAuthenticated = !!user;

  if (!isAuthenticated && localStorage.getItem("AUTH_TOKEN")) {
    setUser(localStorage.getItem("AUTH_TOKEN"));
  }

  const logout = () => {
    dispatch(showLoader());
    fetchUrl({ type: "post", url: USER_APIS.logout }).then((res) => {
      if (res) {
        localStorage.clear();
        setUser(null);
        toast.success("Logout successfully");
        dispatch(hideLoader());
        window.location.replace("/");
      }
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, logout, loading, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
